<template>
    <div>
        <h2>Mixpanel Playground</h2>
        <div>
            <v-btn @click="createNewReportDatalayer">Create new report event with datalayer</v-btn>
        </div>
        <div>
            <v-btn @click="createNewReportMixpanel">Create new report event with mixpanel</v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MixpanelPlayground',

        methods: {
            // Create new report.
            createNewReportDatalayer() {
                window.dataLayer.push({
                    event: 'pa_track_create_report',
                    name: 'create_report',
                    properties: {
                        pa_track_create_report: {
                            name: 'Test Report',
                            implementation: 'datalayer',
                        },
                    },
                });
            },
            createNewReportMixpanel() {
                window.mixpanel.track(
                    'create_report',
                    {
                        name: 'Test Report',
                        implementation: 'mixpanel',
                    },
                );
            },
        },
    };
</script>
